/* index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import-normalize;

*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: no-repeat center center fixed; 
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer base {
  .scrollbar-thin {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
  }

  .scrollbar-thin::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  .scrollbar-thin::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .scrollbar-thin::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  .scrollbar-thin::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .scrollbar-thick {
    scrollbar-width: thick;
    scrollbar-color: #888 #f1f1f1;
  }

  .scrollbar-thick::-webkit-scrollbar {
    width: 16px;
    height: 16px;
  }

  .scrollbar-thick::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .scrollbar-thick::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  .scrollbar-thick::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  /* Custom select arrow */
  .select-arrow {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  .select-arrow::after {
    content: '▼';
    font-size: 12px;
    color: #555;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
  }

  .select-arrow select {
    appearance: none;
    width: 100%;
    padding-right: 2.5rem; 
  }

  .select-arrow::after {
    content: '▼'; /* Custom arrow icon */
    font-size: 1rem; /* Adjust the size as needed */
    color: #000; /* Change the color as needed */
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; 
  }
  .scrollbar-hidden {
    scrollbar-width: none; 
    -ms-overflow-style: none; 
  }

  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px; 
  }

  .modal-overlay {
  z-index: 50; 
  }

  .dropdown-menu {
  z-index: 100;
  }

  .scrollbar-hidden::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  /* App.css or index.css */
.swal-confirm-button {
  background-color: #1346AC !important; /* Change the background color */
  color: white !important; /* Change the text color */
  border: none !important; /* Remove border */
  padding: 10px 24px !important; /* Add padding */
  font-size: 16px !important; /* Adjust font size */
  cursor: pointer !important; /* Change cursor */
}

.swal-confirm-button:hover {
  background-color: #3b82f6 !important; /* Darker shade on hover */
}

}

@layer components {
  .login-title {
    @apply text-4xl font-bold text-center text-green-600;
  }

  .login-form {
    @apply space-y-4;
  }

  .form-label {
    @apply block text-sm font-medium text-gray-700;
  }

  .form-input {
    @apply block w-full px-4 py-3 mt-1 border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm;
  }

  .login-button {
    @apply w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500;
  }

  /* Additional custom component styles */
  .sidebar-icon {
    @apply relative flex items-center justify-center 
    h-12 w-12 mt-2 mb-2 mx-auto  
    bg-gray-400 hover:bg-green-600 dark:bg-gray-800 
    text-green-500 hover:text-white
    hover:rounded-xl rounded-3xl
    transition-all duration-300 ease-linear
    cursor-pointer shadow-lg ;
  }

  .sidebar-tooltip {
    @apply absolute w-auto p-2 m-2 min-w-max left-14 rounded-md shadow-md
    text-white bg-gray-900 
    text-xs font-bold 
    transition-all duration-100 scale-0 origin-left;
  }

  .sidebar-hr {
    @apply bg-gray-200 dark:bg-gray-800 
    border border-gray-200 dark:border-gray-800 rounded-full
    mx-2;
  }

  .top-navigation {
    @apply flex flex-row items-center justify-evenly 
    bg-gray-300 dark:bg-gray-700 bg-opacity-90 
    w-full h-16 
    m-0 
    shadow-lg;
  }

  .top-navigation-icon {
    @apply text-gray-500
    mr-3 ml-4
    transition duration-300 ease-in-out 
    hover:text-pink-400 
    cursor-pointer;
  }

  .top-navigation-icon:first {
    @apply ml-auto mr-4;
  }

  .title-hashtag {
    @apply text-lg tracking-wider font-semibold text-gray-500 
    ml-2 my-auto;
  }

  .title-text {
    @apply text-xl text-gray-500 tracking-wider font-semibold text-opacity-80 
    mr-auto ml-2 my-auto 
    transition duration-300 ease-in-out;
  }

  .search {
    @apply w-1/5 flex items-center justify-start 
    bg-gray-400 dark:bg-gray-600
    text-gray-500
    px-2 h-9 ml-0 mr-0 
    rounded-md shadow-md
    transition duration-300 ease-in-out;
  }

  .search-input {
    @apply w-full font-sans font-semibold
    bg-transparent outline-none 
    text-gray-500  placeholder-gray-500
    pl-1 rounded;
  }

  .content-container {
    @apply flex flex-col 
    bg-gray-300 dark:bg-gray-700
    m-0 
    h-full w-full 
    overflow-hidden;
  }

  .content-list {
    @apply flex flex-col items-center 
    h-full w-full 
    mt-0 ml-0 mx-auto px-0 pb-12 
    overflow-y-scroll;
  }

  .post {
    @apply w-full
    flex flex-row items-center justify-evenly
    py-4 px-8 m-0
    cursor-pointer;
  }

  .post-selected {
    @apply bg-green-400 dark:bg-gray-900
    shadow-md;
  }

  .post-content {
    @apply w-4/5 
    flex flex-col justify-start 
    ml-auto;
  }

  .post-text {
    @apply text-lg text-left text-gray-800 dark:text-white 
    mr-auto whitespace-normal;
  }

  .post-owner {
    @apply text-left font-semibold text-gray-800 dark:text-white mr-2 cursor-pointer;
  }

  .avatar-wrapper {
    @apply flex flex-col items-center
    w-12 
    m-0 ml-auto mb-auto;
  }

  .avatar {
    @apply flex-none
    w-12 h-full 
    rounded-full shadow-md
    object-cover bg-gray-100 
    mb-auto mt-0 mx-0 
    cursor-pointer;
  }

  .timestamp {
    @apply text-xs text-left font-semibold text-gray-500 dark:text-gray-600 ml-2;
  }

  .bottom-bar {
    @apply flex flex-row items-center justify-between 
    fixed left-88 right-8 bottom-2 
    rounded-lg shadow-lg 
    bg-gray-400 dark:bg-gray-600 px-2
    h-12;
  }

  .bottom-bar-input {
    @apply font-semibold w-full
    bg-transparent outline-none 
    ml-0 mr-auto
    text-gray-500  dark:text-gray-400 placeholder-gray-500
    cursor-text;
  }

  .channel-bar {
    @apply w-80 h-auto m-0 ml-16 bg-gray-200 dark:bg-gray-800
     overflow-hidden;
  }

  .channel-container {
    @apply flex flex-col items-center justify-start 
    p-1 m-0;
  }

  .channel-block {
    @apply flex items-center justify-center 
    h-16 
    m-0 p-0;
  }

  .channel-block-text {
    @apply text-lg tracking-wider font-bold 
    text-gray-600 dark:text-gray-400 
    mr-auto ml-4 my-auto align-middle;
  }

  .dropdown {
    @apply m-0 w-full px-2 pb-2
    transition duration-300 ease-in-out;
  }

  .dropdown-header {
    @apply flex flex-row items-center justify-evenly
    mx-0 text-gray-500
    cursor-pointer;
  }

  .dropdown-header-text {
    @apply text-gray-500  text-opacity-90 
    text-lg font-semibold
    cursor-default;
  }

  .dropdown-header-text-selected {
    @apply text-blue-500 text-opacity-90 text-lg font-bold;
  }

  .dropdown-selection {
    @apply flex flex-row items-center justify-evenly
    mt-1 mr-auto ml-2
    transition duration-300 ease-in-out
    cursor-pointer;
  }

  .dropdown-selection-text {
    @apply text-gray-500 font-semibold tracking-wide
    mr-auto 
    transition duration-300 ease-in-out 
    hover:text-pink-500 dark:hover:text-gray-500
    cursor-pointer;
  }
}

@tailwind utilities;

::-webkit-scrollbar {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  @apply bg-gray-500 dark:bg-green-400;
}

/* Print styles */
@media print {
  /* Hide all elements except the one with ID 'printable-area' */
  body * {
      visibility: hidden;
  }
  #printable-area, #printable-area * {
      visibility: visible;
  }
  #printable-area {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
  }
  .status-column,
  .actions-column {
      display: none !important;
  }
}
